import React from 'react';
import { ExternalLink, ChevronRight, Check, Globe } from 'lucide-react';

const ProgressBar = ({ progress }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
    <div 
      className="bg-green-600 h-2.5 rounded-full transition-all duration-500 ease-out"
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

const Button = ({ children, variant = "primary", className = "", ...props }) => {
  const baseClasses = "w-full px-6 py-4 rounded-lg font-semibold text-lg transition-colors duration-200 flex justify-between items-center";
  const variantClasses = {
    primary: "bg-blue-600 text-white hover:bg-blue-700",
    secondary: "bg-red-500 text-white hover:bg-red-600",
  };
  return (
    <button className={`${baseClasses} ${variantClasses[variant]} ${className}`} {...props}>
      {children}
    </button>
  );
};

const ExploreBallotPage = ({ onNext, progress }) => {
  const handleButtonClick = () => {
    // You can add any specific logic here if needed
    onNext();
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-3xl font-bold text-blue-900">
            VoteWise Colorado
          </h1>
          <p className="text-lg text-gray-700">
            Explore Your Ballot 🗳️
          </p>
        </div>
        
        <ProgressBar progress={progress} />
        
        <p className="text-sm text-center text-gray-600">
          <strong>{progress}% Complete</strong> - Let's explore your ballot!
        </p>
        
        <div className="space-y-4">
          <Button 
            variant="primary"
            onClick={()=>window.open("https://myballot.coloradosos.gov/app/home?_gl=1*18b2psh*_ga*OTUwMzYwMDAuMTcyNzI3ODcxMg..*_ga_JDK6PLVHDW*MTcyODc3MzQ1MS42LjEuMTcyODc3MzU3MC4wLjAuMA", '_blank')}
          >
            <span className="flex items-center">
              <ExternalLink className="mr-2 h-6 w-6" />
              View Your Sample Ballot
            </span>
            <ChevronRight className="h-6 w-6" />
          </Button>
          
          <Button 
            variant="primary"
            onClick={()=>window.open("https://www.playlab.ai/project/cm26hgfdb02jynfdeszz2gmtq", '_blank')}
          >
            <span className="flex items-center">
              <Check className="mr-2 h-6 w-6" />
              Candidate Research
            </span>
            <ChevronRight className="h-6 w-6" />
          </Button>
          
          <Button 
            variant="primary"
            onClick={()=>window.open("https://www.playlab.ai/project/cm110nc0i02gulnss4ozup239", '_blank')}
          >
            <span className="flex items-center">
              <Check className="mr-2 h-6 w-6" />
              Ballot Measure Research
            </span>
            <ChevronRight className="h-6 w-6" />
          </Button>

          <Button 
            variant="primary"
            onClick={()=>window.open("https://www.cpr.org/2024/10/14/vg-2024-colorado-voter-guide-to-the-2024-election/", '_blank')}
          >
            <span className="flex items-center">
              <Check className="mr-2 h-6 w-6" />
              CPR Voter Guide
            </span>
            <ChevronRight className="h-6 w-6" />
          </Button>

          <Button 
            variant="secondary"
            onClick={() => handleButtonClick()}
          >
            <span className="flex items-center">
              <ChevronRight className="mr-2 h-6 w-6" />
              I'm a Voter
            </span>
            <ChevronRight className="h-6 w-6" />
          </Button>
        </div>

        <p className="text-sm text-center text-gray-600">
          Take your time to research and understand your ballot. Your informed vote matters!
        </p>

        <p className="text-xs text-center text-gray-500 mt-4">
          Note: This tool provides unbiased information to help you make informed decisions. 
          We do not endorse any candidates or positions on ballot measures.
        </p>
      </div>
    </div>
  );
};

export default ExploreBallotPage;
