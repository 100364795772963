import React from 'react';
import { ChevronRight, Check, HelpCircle, Globe } from 'lucide-react';

const ProgressBar = ({ progress }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
    <div 
      className="bg-green-600 h-2.5 rounded-full transition-all duration-500 ease-out"
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

const VoterRegistrationPage = ({ onNext, progress }) => {
  const handleButtonClick = () => {
    // You can add any specific logic here if needed
    onNext();
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-3xl font-bold text-blue-900">
            VoteWise Colorado
          </h1>
          <p className="text-lg text-gray-700">
            Check your registration status 🗳️
          </p>
        </div>
        
        <ProgressBar progress={progress} />
        
        <p className="text-sm text-center text-gray-600">
          <strong>{progress}% Complete</strong> - Let's confirm your registration!
        </p>
        
        <div className="space-y-4">
          <a 
            href="https://www.sos.state.co.us/voter/pages/pub/olvr/findVoterReg.xhtml" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-between transition duration-300"
          >
            <span className="flex items-center">
              <Check size={20} className="mr-2" />
              Check Your Registration Status
            </span>
            <ChevronRight size={20} />
          </a>

          <a 
            href="https://www.sos.state.co.us/voter/pages/pub/olvr/verifyNewVoter.xhtml" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-between transition duration-300"
          >
            <span className="flex items-center">
              <Check size={20} className="mr-2" />
              Register to Vote
            </span>
            <ChevronRight size={20} />
          </a>
          
          <a 
            href="https://www.sos.state.co.us/pubs/elections/FAQs/Common.html" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-between transition duration-300"
            onClick={() => handleButtonClick(25)}
          >
            <span className="flex items-center">
              <HelpCircle size={20} className="mr-2" />
              Voting FAQs
            </span>
            <ChevronRight size={20} />
          </a>
          
          <button 
            className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-between transition duration-300"
            onClick={() => handleButtonClick(25)}
          >
            <span className="flex items-center">
              <ChevronRight size={20} className="mr-2" />
              Make a Voting Plan
            </span>
            <ChevronRight size={20} />
          </button>
        </div>
        
        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-blue-900">Eligibility Requirements</h2>
          <ul className="list-disc pl-5 space-y-2 text-gray-700">
            <li>At least 16 years old to register (18 to vote on Election Day)</li>
            <li>U.S. citizen</li>
            <li>Colorado resident for at least 22 days before the election</li>
          </ul>
        </div>

        <p className="text-sm text-center text-gray-600">
          Be a wise voter. Your voice shapes Colorado's future!
        </p>

        <p className="text-xs text-center text-gray-500 mt-4">
          Disclaimer: Please ensure you meet all eligibility requirements before registering to vote. This page provides general information and does not collect any personal data.
        </p>
      </div>
    </div>
  );
};

export default VoterRegistrationPage;
