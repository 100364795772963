import React from 'react';
import { ChevronLeft, Mail, Calendar, MapPin, ExternalLink, Globe } from 'lucide-react';

const MailInVotingPage = ({ onBack }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-3xl font-bold text-blue-900">
            Mail-in Voting
          </h1>
          <p className="text-lg text-gray-700">
            Vote from the comfort of your home 📬
          </p>
        </div>
        
        <div className="space-y-6">
          <div className="bg-blue-100 p-4 rounded-lg">
            <h2 className="text-lg font-semibold text-blue-900 mb-2 flex items-center">
              <Calendar size={20} className="mr-2" />
              Important Dates
            </h2>
            <ul className="list-disc pl-5 space-y-2 text-gray-700">
              <li>Oct 17, 2024: Ballots mailed to registered voters</li>
              <li>Oct 28, 2024: Last day to request a mail ballot</li>
              <li>Nov 5, 2024: Election Day - ballots must be received by 7 PM</li>
            </ul>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-blue-900 mb-2">How to Vote by Mail</h2>
            <ol className="list-decimal pl-5 space-y-2 text-gray-700">
              <li>Fill out your ballot carefully</li>
              <li>Place your ballot in the provided secrecy sleeve</li>
              <li>Put the sleeve in the return envelope</li>
              <li>Sign and date the envelope</li>
              <li>Return your ballot by mail or at a drop-off location</li>
            </ol>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-blue-900 mb-2 flex items-center">
              <MapPin size={20} className="mr-2" />
              Ballot Drop-off Locations
            </h2>
            <a 
              href="https://www.sos.state.co.us/pubs/elections/VIP.html" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 flex items-center"
            >
              Find your nearest drop-off location
              <ExternalLink size={16} className="ml-1" />
            </a>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-blue-900 mb-2">Track Your Ballot</h2>
            <a 
              href="https://colorado.ballottrax.net/voter/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 flex items-center"
            >
              Sign up for BallotTrax
              <ExternalLink size={16} className="ml-1" />
            </a>
            <p className="text-sm text-gray-600 mt-1">
              Receive text, email, or voice message updates on your ballot status.
            </p>
          </div>
        </div>

        <button 
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-center transition duration-300"
          onClick={onBack}
        >
          <ChevronLeft size={20} className="mr-2" />
          Back to Voting Plan
        </button>
        
        <p className="text-xs text-center text-gray-500 mt-4">
          Disclaimer: Information provided is for general guidance. Please refer to official sources for the most up-to-date and accurate details.
        </p>
      </div>
    </div>
  );
};

export default MailInVotingPage;
