import React, { useState } from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import Wizard from './components/Wizard';
import AboutPage from './views/about';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {
  const [currentPage, setCurrentPage] = useState('wizard');

  const handleAbout = () => {
    setCurrentPage('about');
  };

  const handleBackToWizard = () => {
    setCurrentPage('wizard');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {currentPage === 'wizard' && (
        <Wizard onAbout={handleAbout} />
      )}
      {currentPage === 'about' && (
        <AboutPage onBack={handleBackToWizard} />
      )}
    </ThemeProvider>
  );
}

export default App;
