import React from 'react';
import { ChevronRight, Info, MessageSquare, Globe } from 'lucide-react';

const ProgressBar = ({ progress }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
    <div 
      className="bg-green-600 h-2.5 rounded-full transition-all duration-500 ease-out" 
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

const Button = ({ children, icon: Icon, variant = "blue", ...props }) => {
  const colorClasses = {
    red: "bg-red-500 hover:bg-red-600 text-white",
    blue: "bg-blue-600 hover:bg-blue-700 text-white",
  };

  return (
    <button 
      className={`w-full ${colorClasses[variant]} font-bold py-3 px-4 rounded-lg flex items-center justify-between transition duration-300 mb-3`}
      {...props}
    >
      <span className="flex items-center">
        <Icon className="mr-2 h-5 w-5" />
        {children}
      </span>
      <ChevronRight className="h-5 w-5" />
    </button>
  );
};

const WelcomePage = ({ onNext, onAbout, progress }) => {
  return (
    <div className="flex flex-col items-center justify-center bg-blue-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-3xl font-bold text-blue-900">
            VoteWise Colorado
          </h1>
          <p className="text-lg text-gray-700">
            Your path to informed voting 🗳️
          </p>
        </div>
        
        <ProgressBar progress={progress} />
        
        <p className="text-sm text-center text-gray-600">
          <strong>{progress}% Complete</strong> - Let's get you ready to vote!
        </p>
        
        <div className="space-y-4">
          <Button onClick={onNext} icon={ChevronRight} variant="red">
            Start Your Voter Journey
          </Button>
          
          <Button onClick={onAbout} icon={Info} variant="blue">
            About This App
          </Button>
          
          <Button onClick={()=>window.open('https://www.playlab.ai/project/cm0zh246804k8f9eerydjlp5j', '_blank')} icon={MessageSquare} variant="blue">
            VoteWise Chatbot
          </Button>
        </div>
        
        <p className="text-sm text-center text-gray-600">
          Be a wise voter. Your voice shapes Colorado's future!
        </p>

        <div className="border-t pt-4">
          <p className="text-sm text-center text-gray-600 mb-2">
            <Globe size={16} className="inline mr-1" />
            Choose your language:
          </p>
          <div className="flex justify-center space-x-2">
            <button onClick={()=>window.location.href = 'https://vote.boxcar.ai'} className="px-3 py-1 text-sm bg-gray-200 hover:bg-gray-300 rounded transition duration-300">
              English
            </button>
            <button onClick={()=>window.location.href = 'https://vote-boxcar-ai.translate.goog/?_x_tr_sl=auto&_x_tr_tl=es&_x_tr_hl=en&_x_tr_pto=wapp'} className="px-3 py-1 text-sm bg-gray-200 hover:bg-gray-300 rounded transition duration-300">
              Español
            </button>
            <button onClick={()=>window.location.href = 'https://vote-boxcar-ai.translate.goog/?_x_tr_sl=auto&_x_tr_tl=vi&_x_tr_hl=en&_x_tr_pto=wapp'} className="px-3 py-1 text-sm bg-gray-200 hover:bg-gray-300 rounded transition duration-300">
              Tiếng Việt
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
