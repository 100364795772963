import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Paper, useTheme, useMediaQuery, AppBar } from '@mui/material';
import WelcomePage from '../views/welcome';
import VoterRegistrationPage from '../views/voter-registration';
import VotingPlanPage from '../views/vote-wise';
import ExploreBallotPage from '../views/explore-ballot';
import ImAVoterPage from '../views/im-a-voter';
import CongratulationsPage from '../views/congratulations';
import MailInVotingPage from '../views/mail-in';
import InPersonVotingPage from '../views/in-person';

const steps = ['Welcome', 'Voter Registration', 'Make Your Voting Plan', 'Explore Your Ballot', 'I\'m a Voter', 'Congratulations'];

function Wizard({ onAbout }) {
  const [currentView, setCurrentView] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setProgress((prevProgress) => Math.min(prevProgress + 20, 100));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setProgress((prevProgress) => Math.max(prevProgress - 20, 0));
  };

  const handleReset = () => {
    setActiveStep(0);
    setProgress(0);
    setCurrentView(null);
  };

  const getStepContent = (step) => {
    if (currentView === 'mail-in') {
      return <MailInVotingPage onBack={() => setCurrentView(null)} />;
    }
    if (currentView === 'in-person') {
      return <InPersonVotingPage onBack={() => setCurrentView(null)} />;
    }

    switch (step) {
      case 0:
        return <WelcomePage onNext={handleNext} onAbout={onAbout} progress={progress} />;
      case 1:
        return <VoterRegistrationPage onNext={handleNext} progress={progress} />;
      case 2:
        return <VotingPlanPage 
          onNext={handleNext} 
          progress={progress} 
          onMailIn={() => setCurrentView('mail-in')}
          onInPerson={() => setCurrentView('in-person')}
        />;
      case 3:
        return <ExploreBallotPage onNext={handleNext} progress={progress} />;
      case 4:
        return <ImAVoterPage onNext={handleNext} progress={progress} />;
      case 5:
        return <CongratulationsPage onReset={handleReset} progress={progress} onStartOver={handleReset} />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <Box sx={{ width: '100%', p: 0, position: 'relative' }}>
      <AppBar position="fixed" color="default" elevation={0} sx={{ top: 0, borderBottom: '1px solid #e0e0e0' }}>
        <Stepper activeStep={activeStep} orientation="horizontal" sx={{ p: 1, overflowX: 'auto' }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{isMobile ? '' : label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </AppBar>
      <Paper elevation={3} sx={{ p: isMobile ? 2 : 3, mt: '64px' }}>
        <Box class="flex justify-center items-center" sx={{ mt: 2, mb: 1 }}>
          {getStepContent(activeStep)}
        </Box>
        {activeStep !== steps.length - 1 && (
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: isMobile ? 0 : 1, mb: isMobile ? 1 : 0 }}
              fullWidth={isMobile}
            >
              Back
            </Button>
            {!isMobile && <Box sx={{ flex: '1 1 auto' }} />}
            <Button onClick={handleNext} fullWidth={isMobile}>
              {activeStep === steps.length - 2 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default Wizard;
