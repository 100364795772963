import React from 'react';
import { ArrowLeft, ExternalLink } from 'lucide-react';
const LinkWithIcon = ({ href, children }) => (
  <a 
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-blue-600 hover:text-blue-800 inline-flex items-center"
  >
    {children}
    <ExternalLink size={16} className="ml-1" />
  </a>
);

const AboutPage = ({ onBack }) => {
  
  const studentNames = [
    "Manny Munoz", "Mike P", "Khaled Ayoobi", "Joshua Sandoval", "Eduardo Mendez",
    "Nevaeh Bonilla", "Leonard J. Aragon III", "Nick Flores", "kuplo", "Tiramisu",
    "Edwin Alva", "Hector Sanchez", "Marbella Rodriguez", "Daisy Quintana",
    "Evelyn Amador", "Walid", "Juan", "Abigail Zamarripa", "Kole V",
    "Morion Keilwitz", "Adin Velasquez", "Lesley Barrera", "Jordan G",
    "Anastasia Elizondo", "Caden Mabasa", "Andres Vasquez", "Kimberly Rivera",
    "Ailyn DeSantiago", "Ivory", "Sergio Galicia", "John Pork", "Joshua Martinez",
    "Jeremiah Franco", "Omars Ordonez", "Ryan N", "Isaias N", "Sofia Mendoza-Martinez",
    "Angel R Garcia", "Anonymous", "Santi"
  ];

  return (
    <div className="flex flex-col items-center justify-center bg-blue-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 space-y-6">
        <button
          onClick={onBack}
          className="flex items-center text-blue-600 hover:text-blue-800 transition duration-300"
        >
          <ArrowLeft className="mr-2 h-5 w-5" />
          Back to Welcome
        </button>

        <div className="text-center space-y-2">
          <h1 className="text-3xl font-bold text-blue-900">About VoteWise Colorado</h1>
        </div>

        <div className="space-y-4">
        <section>
          <h2 className="text-2xl font-semibold text-blue-800">About This App</h2>
          <p className="text-gray-700">
            VoteWise Colorado is your comprehensive guide to informed voting in Colorado. Our mission is to empower voters with clear, unbiased information about candidates, ballot measures, and the voting process. We are committed to making this information accessible to all voters, regardless of their abilities or background, ensuring that everyone has the opportunity to participate fully in our democracy.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-blue-800">Our Story</h2>
          <p className="text-gray-700">
            This VoteWise Colorado app is a collaboration between industry, not-for-profit partners, students, teachers, and AI. Zach Kennelly, Gianna Geraffo and Justin Williams worked together to build a plan to help students leverage AI to make an impact on issues they're passionate about. We believed that building software focused on real issues would be a powerful approach, fostering an environment of curiosity, skepticism, abundance, and humility.
          </p>
          <p className="text-gray-700 mt-2">
            Students built bots on Playlab, a not-for-profit AI company, using the RCP Method for prompting AI. Many students were interested in voting and being informed voters, with a focus on greater accessibility.
          </p>
          <p className="text-gray-700 mt-2">
            This app is the result of student groups working in Agile roles, leveraging Playlab.ai as a thought partner and using Playlab and Claude to write code for each page. We then used BoxCar.ai to code the backend and tie everything together.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-blue-800">Credits</h2>
          <p className="text-gray-700">This app was developed by awesome students and teachers of DSST:</p>
          <ul className="list-disc pl-5 text-gray-700">
            <li>DSST: College View Seniors</li>
            <li>Teachers: Zach Kennelly and Gianna Geraffo</li>
            <li>Industry Partners:
              <ul className="list-disc pl-5">
                <li>Justin Williams of <a href="https://www.tinmankinetics.com" target="_blank">Tinman Kinetics</a></li>
                <li>Susan Miller of <a href='https://www.bridgeviewit.com/services/technology-consulting/ai-consulting/' target='_blank'>BridgeView IT</a></li>
                <li>Yusuf Ahmad and Wyman Khuu of <a href='https://www.playlab.ai'>Playlab</a></li>
              </ul>
            </li>
            <li>Developed during Collaborative Learning Adventures workshop with Gregg Cannady and Audrey Holmes</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-blue-800">Tools Used</h2>
          <ul className="list-disc pl-5 text-gray-700">
            <li><LinkWithIcon href="https://www.playlab.ai/">Playlab</LinkWithIcon></li>
            <li><LinkWithIcon href="https://tinmankinetics.com/">BoxCar</LinkWithIcon></li>
            <li><LinkWithIcon href="https://claude.ai/chat">Claude</LinkWithIcon></li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-blue-800">Student Names</h2>
          <p className="text-gray-700 mb-2">
            The following students contributed to this project:
          </p>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
            {studentNames.map((name, index) => (
              <div key={index} className="text-sm text-gray-700">{name}</div>
            ))}
          </div>
        </section>

        <p className="text-sm text-gray-600 mt-6">
          © 2024 The RCP Method and Tinman Kinetics. All rights reserved.
        </p>

      </div>
    </div>
    </div>
  );
};

export default AboutPage;
