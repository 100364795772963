import React from 'react';
import { Award, Check, Share2, RefreshCw, Globe, Share } from 'lucide-react';

const ProgressBar = ({ progress }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
    <div 
      className="bg-green-600 h-2.5 rounded-full transition-all duration-500 ease-out"
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

const Button = ({ children, variant = "primary", className = "", ...props }) => {
  const baseClasses = "w-full px-6 py-4 rounded-lg font-semibold text-lg transition-colors duration-200 flex justify-between items-center";
  const variantClasses = {
    primary: "bg-blue-600 text-white hover:bg-blue-700",
    secondary: "bg-green-500 text-white hover:bg-green-600",
    tertiary: "bg-purple-500 text-white hover:bg-purple-600",
  };
  return (
    <button className={`${baseClasses} ${variantClasses[variant]} ${className}`} {...props}>
      {children}
    </button>
  );
};

const CongratulationsPage = ({ onReset, progress, onStartOver }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 space-y-6">
        <ProgressBar progress={100} />
        
        <div className="text-center space-y-2">
          <Award className="h-16 w-16 text-yellow-400 mx-auto" />
          <h1 className="text-3xl font-bold text-blue-900">
            Congratulations!
          </h1>
          <p className="text-lg text-gray-700">
            You're an informed and engaged voter! 🎉
          </p>
        </div>
        
        <p className="text-sm text-center text-gray-600">
          <strong>100% Complete</strong> - You've finished your voter journey!
        </p>
        
        <div className="bg-blue-100 rounded-lg p-4">
          <h2 className="text-xl font-semibold text-blue-900 mb-2">Your Achievements:</h2>
          <ul className="space-y-2">
            <li className="flex items-center">
              <Check className="h-5 w-5 text-green-500 mr-2" />
              <span>Registered to vote</span>
            </li>
            <li className="flex items-center">
              <Check className="h-5 w-5 text-green-500 mr-2" />
              <span>Created a voting plan</span>
            </li>
            <li className="flex items-center">
              <Check className="h-5 w-5 text-green-500 mr-2" />
              <span>Researched candidates and measures</span>
            </li>
            <li className="flex items-center">
              <Check className="h-5 w-5 text-green-500 mr-2" />
              <span>Ready to make your voice heard!</span>
            </li>
          </ul>
        </div>
        
        <p className="text-center text-gray-700">
          Your participation strengthens our democracy. Keep up the great work!
        </p>
        
        <div className="space-y-4">
          <Button onClick={()=>{try{navigator.share({
  title: "DSST Vote!",
  text: "Step by Step Voter Registration Guidance",
  url: "https://vote.boxcar.ai",
});} catch(_e){navigator.clipboard.writeText("https://vote.boxcar.ai"); alert("DSST Vote URL copied to clipboard.")}}} variant="tertiary">
            <span className="flex items-center">
              <Share className="mr-2 h-6 w-6" />
              Share the App
            </span>
          </Button>

          <Button onClick={onStartOver} variant="secondary">
            <span className="flex items-center">
              <RefreshCw className="mr-2 h-6 w-6" />
              Start Over
            </span>
          </Button>
        </div>

        <p className="text-xs text-center text-gray-500 mt-4">
          Remember: Every election is an opportunity to shape your community. 
          Stay informed and keep participating!
        </p>
      </div>
    </div>
  );
};

export default CongratulationsPage;
