import React from 'react';
import { ChevronLeft, Users, Calendar, MapPin, ExternalLink, Globe, Clock } from 'lucide-react';

const InPersonVotingPage = ({ onBack }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-3xl font-bold text-blue-900">
            In-Person Voting
          </h1>
          <p className="text-lg text-gray-700">
            Cast your vote at a polling location 🗳️
          </p>
        </div>
        
        <div className="space-y-6">
          <div className="bg-blue-100 p-4 rounded-lg">
            <h2 className="text-lg font-semibold text-blue-900 mb-2 flex items-center">
              <Calendar size={20} className="mr-2" />
              Important Dates
            </h2>
            <ul className="list-disc pl-5 space-y-2 text-gray-700">
              <li>Oct 21, 2024: Early voting begins</li>
              <li>Nov 5, 2024: Election Day</li>
            </ul>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-blue-900 mb-2 flex items-center">
              <Clock size={20} className="mr-2" />
              Early Voting
            </h2>
            <p className="text-gray-700 mb-2">
              Early voting allows you to cast your ballot before Election Day, often with shorter wait times.
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-700">
              <li>Dates: October 21 - November 4, 2024</li>
              <li>Hours may vary by location</li>
              <li>You can vote at any early voting center in your county</li>
            </ul>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-blue-900 mb-2 flex items-center">
              <Calendar size={20} className="mr-2" />
              Election Day Voting
            </h2>
            <p className="text-gray-700 mb-2">
              On Election Day, you can vote at your assigned polling place or any vote center in your county.
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-700">
              <li>Date: November 5, 2024</li>
              <li>Hours: 7:00 AM to 7:00 PM</li>
              <li>You can vote as long as you're in line by 7:00 PM</li>
            </ul>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-blue-900 mb-2 flex items-center">
              <MapPin size={20} className="mr-2" />
              Find Your Polling Location
            </h2>
            <a 
              href="https://www.sos.state.co.us/pubs/elections/VIP.html" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 flex items-center"
            >
              Locate your nearest voting center
              <ExternalLink size={16} className="ml-1" />
            </a>
          </div>
          
          <div>
            <h2 className="text-lg font-semibold text-blue-900 mb-2">What to Bring</h2>
            <p className="text-gray-700 mb-2">
              Colorado requires identification to vote in person. Acceptable forms of ID include:
            </p>
            <ul className="list-disc pl-5 space-y-2 text-gray-700">
              <li>Colorado driver's license or ID card</li>
              <li>U.S. passport</li>
              <li>Employee ID card with photo issued by the U.S. government or Colorado</li>
              <li>Pilot's license</li>
              <li>U.S. military ID with photo</li>
              <li>Tribal ID card</li>
            </ul>
          </div>
        </div>

        <button 
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-center transition duration-300"
          onClick={onBack}
        >
          <ChevronLeft size={20} className="mr-2" />
          Back to Voting Plan
        </button>

        <p className="text-xs text-center text-gray-500 mt-4">
          Disclaimer: Information provided is for general guidance. Please refer to official sources for the most up-to-date and accurate details.
        </p>
      </div>
    </div>
  );
};

export default InPersonVotingPage;
