import React from 'react';
import { ChevronRight, Check, BarChart2, MessageSquare, Globe } from 'lucide-react';

const ProgressBar = ({ progress }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
    <div 
      className="bg-green-600 h-2.5 rounded-full transition-all duration-500 ease-out"
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

const Button = ({ children, variant = "primary", className = "", ...props }) => {
  const baseClasses = "w-full px-6 py-4 rounded-lg font-semibold text-lg transition-colors duration-200 flex justify-between items-center";
  const variantClasses = {
    primary: "bg-blue-600 text-white hover:bg-blue-700",
    secondary: "bg-red-500 text-white hover:bg-red-600",
  };
  return (
    <button className={`${baseClasses} ${variantClasses[variant]} ${className}`} {...props}>
      {children}
    </button>
  );
};

const ImAVoterPage = ({ onNext, progress }) => {
  const handleButtonClick = () => {
    // You can add any specific logic here if needed
    onNext();
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-3xl font-bold text-blue-900">
            I'm a Voter!
          </h1>
          <p className="text-lg text-gray-700">
            Congratulations on being an engaged citizen! 🎉
          </p>
        </div>
        
        <ProgressBar progress={progress} />
        
        <p className="text-sm text-center text-gray-600">
          <strong>{progress}% Complete</strong> - You're making a difference!
        </p>
        
        <div className="space-y-4">
          <Button 
            variant="primary"
            onClick={()=>window.open("https://ballottrax.coloradosos.gov/voter/?_gl=1*7xcp6b*_ga*MTkzNzg4NjUyMS4xNzI1NTQ2MzM2*_ga_JDK6PLVHDW*MTcyODc1ODQxNy4yMy4wLjE3Mjg3NTg0MTcuMC4wLjA", '_blank')}
          >
            <span className="flex items-center">
              <Check className="mr-2 h-6 w-6" />
              Track My Ballot
            </span>
            <ChevronRight className="h-6 w-6" />
          </Button>

          <Button 
            variant="primary"
            onClick={()=>window.open("https://www.sos.state.co.us/pubs/elections/resultsData.html", '_blank')}
          >
            <span className="flex items-center">
              <BarChart2 className="mr-2 h-6 w-6" />
              Election Results
            </span>
            <ChevronRight className="h-6 w-6" />
          </Button>
          
          <Button 
            variant="primary"
            onClick={()=>window.open("https://www.playlab.ai/project/cm26i7qlo02lk4qvguy6ayaa3", '_blank')}
          >
            <span className="flex items-center">
              <MessageSquare className="mr-2 h-6 w-6" />
              Civic Engagement Bot
            </span>
            <ChevronRight className="h-6 w-6" />
          </Button>

          <Button 
            variant="secondary"
            onClick={handleButtonClick}
          >
            <span className="flex items-center">
              <ChevronRight className="mr-2 h-6 w-6" />
              Finish!
            </span>
            <ChevronRight className="h-6 w-6" />
          </Button>
        </div>

        <p className="text-sm text-center text-gray-600">
          Your vote matters! Stay engaged and continue to make your voice heard in your community.
        </p>

        <p className="text-xs text-center text-gray-500 mt-4">
          Remember: Your engagement in the democratic process doesn't end with voting. 
          Stay informed and active in your community!
        </p>
      </div>
    </div>
  );
};

export default ImAVoterPage;
