import React from 'react';
import { ChevronRight, Mail, Users, MapPin, Globe, Calendar } from 'lucide-react';

const ProgressBar = ({ progress }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
    <div 
      className="bg-green-600 h-2.5 rounded-full transition-all duration-500 ease-out"
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

const VotingPlanPage = ({ onNext, progress, onMailIn, onInPerson }) => {
  const handleButtonClick = (nextView) => {
    // You can add any specific logic here if needed
    onNext(nextView);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50 p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-md p-6 space-y-6">
        <div className="text-center space-y-2">
          <h1 className="text-3xl font-bold text-blue-900">
            VoteWise Colorado
          </h1>
          <p className="text-lg text-gray-700">
            Make Your Voting Plan 📝
          </p>
        </div>
        
        <ProgressBar progress={progress} />
        
        <p className="text-sm text-center text-gray-600">
          <strong>{progress}% Complete</strong> - Let's plan your vote!
        </p>
        
        <div className="space-y-4">
          <button 
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-between transition duration-300"
            onClick={onMailIn}
          >
            <span className="flex items-center">
              <Mail size={20} className="mr-2" />
              Mail-in Voting Information
            </span>
            <ChevronRight size={20} />
          </button>
          
          <button 
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-between transition duration-300"
            onClick={onInPerson}
          >
            <span className="flex items-center">
              <Users size={20} className="mr-2" />
              In-Person Voting Information
            </span>
            <ChevronRight size={20} />
          </button>
          
          <button 
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-between transition duration-300"
            onClick={()=>window.open('https://justvotecolorado.org/locations/', '_target')}
          >
            <span className="flex items-center">
              <MapPin size={20} className="mr-2" />
              Find Voting Locations
            </span>
            <ChevronRight size={20} />
          </button>
        </div>

        <div className="bg-blue-100 p-4 rounded-lg">
          <h2 className="text-lg font-semibold text-blue-900 mb-2 flex items-center">
            <Calendar size={20} className="mr-2" />
            Important Dates
          </h2>
          <ul className="list-disc pl-5 space-y-2 text-gray-700">
            <li>Oct 21, 2024: Early voting begins</li>
            <li>Oct 28, 2024: Last day to register online or by mail</li>
            <li>Nov 1, 2024: Last day for mail ballots to be sent</li>
            <li>Nov 5, 2024: Election Day (7 AM - 7 PM)</li>
          </ul>
        </div>
        
        <button 
          className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-4 rounded-lg flex items-center justify-between transition duration-300"
          onClick={() => handleButtonClick('explore-ballot')}
        >
          <span className="flex items-center">
            <ChevronRight size={20} className="mr-2" />
            Explore Your Ballot
          </span>
          <ChevronRight size={20} />
        </button>

        <p className="text-sm text-center text-gray-600">
          Planning ahead ensures your vote counts. Be prepared!
        </p>

        <p className="text-xs text-center text-gray-500 mt-4">
          Disclaimer: Voting methods and locations may vary. Please check official sources for the most up-to-date information.
        </p>
      </div>
    </div>
  );
};

export default VotingPlanPage;
